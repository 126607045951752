<template>
  <BaseListPage locale-section="pages.places" route="place">
    <PlacesList />
  </BaseListPage>
</template>

<script>
export default {
  name: "PlacesPage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    PlacesList: () => import("./PlacesList")
  }
};
</script>
